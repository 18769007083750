import React from 'react';
import {
  getFirstAvailableUnit,
  getParentBlockById,
} from '../../hooks/utils/iframe';
import { getParameterByName } from '../../hooks/helpers';
import './CourseIframeSecondaryMenu.css';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';

function getDisplayName(courseData, indices) {
  let current = courseData;
  for (let index of indices) {
    if (current && current.children && current.children[index]) {
      current = current.children[index]; // Navigate to the next level
    } else {
      return undefined;
    }
  }
  return current.display_name;
}

const updateQueryParam = (urlSearchParams, key, value) => {
  const params = new URLSearchParams(urlSearchParams);
  params.set(key, value);
  return params.toString();
};

function getFirstThreeLevels(course) {
  const result = [];

  // Helper function to traverse the tree level by level
  function traverse(node, level) {
    if (level > 3) return; // Stop after 3 levels

    // Check if the current node has resume_block = true
    if (node?.resume_block) {
      result[level - 1] = node.display_name;
    }

    // Traverse the children if the level is within 3
    if (node?.children && node?.children.length > 0) {
      for (const child of node?.children) {
        traverse(child, level + 1);
      }
    }
  }

  // Start traversing from the root node
  traverse(course, 1);
  // If any level is missing, return null
  return result.length === 3 ? result : null;
}

const CourseIframeSecondaryMenu = ({
  courseData,
  handleOutlineClick,
  edxIframe = null,
  ...rest
}) => {
  let data = null;
  const firstThreeNames = getFirstThreeLevels(courseData);
  if (getParameterByName('unit_id') === null) {
    data = getParentBlockById(
      courseData.children,
      getFirstAvailableUnit(courseData).id
    );
  } else {
    data = getParentBlockById(
      courseData.children,
      getParameterByName('unit_id')
    );
  }
  let indices = data.foundIndices;

  return (
    <div
      className={`secondary_menu ${process.env.REACT_APP_IBL_ENABLE_MENTOR ? 'mentor-modal-available' : ''}`}
      {...rest}
    >
      <div className="secondary_options">
        <a
          href="#"
          id="about"
          data-id={courseData?.id}
          className="secondary_link"
          onClick={handleOutlineClick}
        >
          {firstThreeNames?.[0] || courseData?.display_name}
        </a>
        <div className="f_a dr">
          <strong className="bold-text-8 dr"></strong>
        </div>
        <a
          href="#"
          id="about"
          data-id={courseData.children[indices[0]].children[0].children[0].id}
          className="secondary_link"
          onClick={handleOutlineClick}
        >
          {firstThreeNames?.[1] || courseData.children[indices[0]].display_name}
        </a>
        <div className="f_a dr">
          <strong className="bold-text-8 dr"></strong>
        </div>
        <a
          href="#"
          id="about"
          className="secondary_link"
          data-id={
            courseData.children[indices[0]].children[indices[1]].children[0].id
          }
          onClick={handleOutlineClick}
        >
          {firstThreeNames?.[2] ||
            courseData.children[indices[0]].children[indices[1]].display_name}
        </a>
      </div>
    </div>
  );
};
export default CourseIframeSecondaryMenu;
