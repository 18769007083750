import { Outlet } from 'react-router-dom';
import {
  AppDataContext,
  Copilot,
  useUserTimeTracking,
  api,
  VisibilityContext,
} from '@iblai/ibl-web-react-common';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './DashboardView.css';
import { useContext, useEffect, useRef } from 'react';
import CopilotLauncher from '../../components/CopilotLauncher/CopilotLauncher';
import CopilotCloser from '../../components/CopilotCloser/CopilotCloser';
import useIframeMessage from '../../hooks/useIframeMessage';
const SHOW_MENTOR = process.env.REACT_APP_IBL_ENABLE_MENTOR === 'true';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const DashboardView = () => {
  const { appData, setAppData } = useContext(AppDataContext);
  const { visibilityData } = useContext(VisibilityContext);
  useIframeMessage();
  useUserTimeTracking(api);

  return (
    <span>
      <span className="af-view">
        <div className="body">
          <div className="full">
            <div
              className={`main-content ${inIframe() ? '' : 'not-in-iframe'}`}
              style={{
                ...(SHOW_MENTOR ? {} : { paddingRight: 'unset' }),
                ...(inIframe()
                  ? { marginRight: '0px', paddingRight: '0px' }
                  : {}),
              }}
            >
              <div className="div-block-326">
                {!inIframe() && visibilityData.showHeader && <Header></Header>}
                {/*{!inIframe() && <MobileHeader></MobileHeader>}*/}
                <Outlet />
                {!inIframe() && <Footer></Footer>}
              </div>
              {/*<CopilotLauncher />*/}
            </div>
            {!inIframe() && SHOW_MENTOR && <Copilot />}
            {/*<CopilotCloser />*/}
          </div>
          {/*<CopilotLauncher />*/}
        </div>
      </span>
    </span>
  );
};

export default DashboardView;
